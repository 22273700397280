import React from 'react'
import './header.scss'
import Logo_header from '../../../assets/icons/GOV.CO_Logo-whiteGOV.CO_Logo-white.png';

export default function Header() {
    return (
        <div className="header">
            <span className="header__brand">
                <img alt="Logo" src={Logo_header}></img>
            </span>
            {/* <button className="btn_login" onClick={() => window.open(URL_VISOR_RELATORIAS)} >Iniciar sesión</button> */}
            <span className="header__link"><a className="a_header" href="https://nuvu.cc/">Ir a Nuvu</a></span>
        </div>
    )
}
