import React from 'react'
import './footer.scss'
import Logo_fb from '../../../assets/icons/Facebook.png';
import Logo_ig from '../../../assets/icons/Instagram.png';
import Logo_yt from '../../../assets/icons/Youtube.png';
import Logo_m from '../../../assets/icons/Mail.png';
import Logo_tw from '../../../assets/icons/Twitter.png';
// import { useSelector } from 'react-redux';
// import { getFooterStatus } from '../../store/selectors';

export default function Footer(props) {

    // const footerState = useSelector(getFooterStatus);

    return (
        <>
            <div className="footer">
                <div className="footer__info">
                    <span className="footer__title">LÍNEA DE ATENCIÓN AL CONSUMIDOR</span>
                    <span className="footer__label">Contact center</span>
                    <span className="footer__value">592 0400</span>
                    <span className="footer__label">Línea gratuita</span>
                    <span className="footer__value link" onClick={(e) => window.open(`https://servicioslinea.sic.gov.co/servilinea/PQRSF2/`)}>Presentación de PQRFS</span>
                </div>
                <div className="footer__info">
                    <span className="footer__title">CONMUTADOR</span>
                    <span className="footer__value no-margin-bottom">(571) 587 00 00 - Bogotá</span>
                    <span className="footer__label">Código Postal</span>
                    <span className="footer__value">110311</span>
                    <span className="footer__label">NIT</span>
                    <span className="footer__value">800176089 - 2</span>
                </div>
                <div className="footer__info col3">
                    <span className="footer__title">SEDE PRINCIPAL</span>
                    <span className="footer__value no-margin-bottom">Carrera 13 No. 27 - 00. Pisos 1y 3</span>
                    <span className="footer__label">Horario de Atención al Público</span>
                    <span className="footer__value">Lunes a Viernes de 8:00 a.m a 4:30 p.m</span>
                    <span className="footer__label">Correo Notificaciones Judiciales</span>
                    <span className="footer__value link" onClick={() => window.open(`mailto:info@nuvu.cc`)} >info@nuvu.cc</span>
                </div>
                <div className="footer__info logos">
                    <span className="footer__title logos-title">SÍGANOS</span>
                    <span className="footer__value no-margin-bottom">
                        <img style={{ cursor: 'pointer' }} onClick={() => window.open(`https://www.facebook.com/Superintendenciadeindustriaycomercio/`)} href="" alt="contact" src={Logo_fb}></img>
                        <img style={{ cursor: 'pointer' }} onClick={() => window.open(`https://twitter.com/sicsuper`)} alt="contact" src={Logo_tw}></img>
                        <img style={{ cursor: 'pointer' }} onClick={() => window.open(`https://www.youtube.com/user/superintendencia2011?feature=mhee`)} alt="contact" src={Logo_yt}></img>
                        <img style={{ cursor: 'pointer' }} onClick={() => window.open(`https://www.instagram.com/superintendencia_sic/`)} alt="contact" src={Logo_ig}></img>
                        <img style={{ cursor: 'pointer' }} onClick={() => window.open(`mailto:contactenos@sic.gov.co`)} alt="contact" src={Logo_m}></img>
                    </span>
                </div>
            </div>
        </>
    )
}
