import {
    INPUT_SEARCH_TEXT,
    CLEAN_SEARCH_TEXT,
    UPDATE_FILTERS,
    SET_FILTERS_LIST_VALUES,
    TOGGLE_FOOTER,
    SAVE_SEARCH_RESULTS,
    SAVE_DATE_RESULTS,
    SAVE_RESULT,
    DELETE_SAVED_RESULT,
    SET_DOC_SEARCH,
    SET_LOADING_RESULTS,
    SET_LOADING_RESULTS_FALSE,
    SET_LOADING_RESULTS_TRUE,
    SEARCH_TRIGGER_TRUE,
    SEARCH_TRIGGER_FALSE,
    SET_SORT_FILTER,
    SET_RESULTS_VIEW_PAGE
} from '../actions/types';
import {
    getInitialState,
    setInputSearchText,
    cleanInputSearchText,
    updateFilters,
    setFiltersListValues,
    toggleFooter,
    saveSearchResults,
    saveDateResults,
    saveResults,
    deleteResult,
    setDocumentSearchText,
    setLoadingResults,
    setLoadingResultsFalse,
    setLoadingResultsTrue,
    setSearchTriggerTrue,
    setSearchTriggerFalse,
    setSortType,
    setResultsViewPage
} from './implementation'

const initialState = getInitialState();

export default function searchReducer(state = initialState, action) {
    switch (action.type) {
        case INPUT_SEARCH_TEXT:
            return setInputSearchText(state, action);
        case CLEAN_SEARCH_TEXT:
            return cleanInputSearchText(state, action);
        case UPDATE_FILTERS:
            return updateFilters(state, action);
        case SET_FILTERS_LIST_VALUES:
            return setFiltersListValues(state, action);
        case TOGGLE_FOOTER:
            return toggleFooter(state);
        case SAVE_SEARCH_RESULTS:
            return saveSearchResults(state, action);
        case SAVE_DATE_RESULTS:
            return saveDateResults(state, action);
        case SAVE_RESULT:
            return saveResults(state, action);
        case DELETE_SAVED_RESULT:
            return deleteResult(state, action);
        case SET_DOC_SEARCH:
            return setDocumentSearchText(state, action);
        case SET_LOADING_RESULTS:
            return setLoadingResults(state);
        case SET_LOADING_RESULTS_FALSE:
            return setLoadingResultsFalse(state);
        case SET_LOADING_RESULTS_TRUE:
            return setLoadingResultsTrue(state);
        case SEARCH_TRIGGER_TRUE:
            return setSearchTriggerTrue(state);
        case SEARCH_TRIGGER_FALSE:
            return setSearchTriggerFalse(state);
        case SET_SORT_FILTER:
            return setSortType(state, action);
        case SET_RESULTS_VIEW_PAGE:
            return setResultsViewPage(state, action);
        default:
            return state;
    }
}