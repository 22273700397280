import { Provider } from "react-redux";
import Footer from "./app/components/Footer";
import Header from "./app/components/Header";
import Routes from "./app/routes";
import store from "./app/store";

const App = () => {
  return (
    <>
      <Provider store={store}>
        <Header />
        <div>
          <Routes />
        </div>
        <Footer />
        <div className="copy-right">
            <span>© nuvu. Todos los derechos reservados.</span>
            <span>Política de uso de contenido página web. Política Editorial. Aviso de Privacidad. Habeas Data.</span>
        </div>
      </Provider>
    </>
  );
};

export default App;
